import React from 'react';
import Layout from '../components/layout';
import Heading1 from '../components/blocks/heading-1';
import Paragraph from '../components/blocks/paragraph';

const NotFoundPage = () => (
    <Layout>
        <Heading1>404: Page not found</Heading1>
        <Paragraph>You just hit a route that doesn&#39;t exist.</Paragraph>
    </Layout>
);

export default NotFoundPage;
